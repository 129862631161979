<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: NewspaperPath.name }">
          Newpapers
        </router-link>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit" @reset="reset" v-if="show">
      <b-card header="Create Newspaper" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row class="row">
          <b-col md="6">
            <b-form-group label="Brand">
              <multiselect v-model="form.brand" label="name" track-by="name" placeholder="Enter Brand"
                open-direction="bottom" required="required" :options="brandSearchOptions" :searchable="true"
                :loading="isBrandSearch" :close-on-select="true" :multiple="false" :allow-empty="false"
                @search-change="brandFind" @input="brandSelected">
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-display" label="Display Name (Item’s Title)" label-for="display">
              <b-form-input id="display" v-model="form.display_name" type="text" placeholder="Enter Display Name"
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6 button-date-custom">
            <DatetimeCustom
              :label="'Release Date (GMT ' + timezoneString() + ')'"
              v-model="form.release_date"
              :maxDate=true
            ></DatetimeCustom>
          </b-col>
          <b-col md="6 button-date-custom">
            <DatetimeCustom
              :label="'Release Schedule (GMT ' + timezoneString() + ')'"
              v-model="form.release_schedule"
              :maxDate=true
            ></DatetimeCustom>
          </b-col>
          <b-col md="12">
            <b-button class="mr-3" type="submit" variant="primary">
              Save
            </b-button>
            <b-button type="reset" variant="outline-primary">Reset Form</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { NewspaperPath } from '../../router/content';
import constant from '../../store/constant';
import DatetimeCustom from "../../components/form/Datetime.vue"

export default {
  name: "newnewspaper",
  components: {
    DatetimeCustom
  },
  data() {
    return {
      NewspaperPath,
      form: {
        brand: null,
        display_name: "",
        release_date: moment().toString(),
        release_schedule: moment().toString(),
      },
      isBrandSearch: false,
      brandSearchOptions: [],
      show: true,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.newspaper.isError,
      isLoading: (state) => state.newspaper.isLoading,
      errorMessage: (state) => state.newspaper.errorMessage,
      successMessage: (state) => state.newspaper.successMessage,
      item: (state) => state.newspaper.item,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push(NewspaperPath);
    },
    'form.release_schedule': {
    handler() {
      this.updateDisplayName();
    },
    deep: true,
  },
}, 
 created() {
    this.brandFind('');
  },
  methods: {
    ...mapActions("brands", ["searchBrands"]),
    ...mapActions("newspaper", ["postNewspaper"]),
    onSubmit(event) {
      event.preventDefault();
      const payload = {
        brand_id: this.form.brand?.id,
        release_date: this.form.release_date,
        release_schedule: this.form.release_schedule,
      };
      this.postNewspaper(payload);
    },

    brandFind(query) {
      this.isBrandSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          type: constant.ITEM_TYPES.NEWSPAPER.toLowerCase(),
          limit: 10,
        };
        this.searchBrands(payload).then((response) => {
          this.brandSearchOptions = response.data.data.rows;
          this.isBrandSearch = false;
        }).catch(() => {
          this.isBrandSearch = false;
        });
      }, 600);
    },

    async brandSelected() {
      if (!this.form.release_date || !this.form.brand) return;
      const dateNow = moment(this.form.release_date).format('DD MMM YYYY');
      this.form.display_name = `${this.form.brand?.name} / ${dateNow.toUpperCase()}`;
    },
    updateDisplayName() {
  if (moment(this.form.release_schedule).isValid()) {
    const release_schedule = moment(this.form.release_schedule).format('DD MMM YYYY').toUpperCase();
    const brandName = this.form.brand?.name || 'Enter Display Name';
    this.form.display_name = `${brandName} / ${release_schedule}`;
  } else {
    this.form.display_name = this.form.brand?.name || 'Enter Display Name';
  }
},
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  }
};
</script>

<style lang="css">
.create-new {
  font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
}
.button-date-custom .button-date-now {
  display: none;
}
</style>
