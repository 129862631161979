var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.NewspaperPath.name
      }
    }
  }, [_vm._v(" Newpapers ")])], 1)], 1), _vm.show ? _c('b-form', {
    on: {
      "submit": _vm.onSubmit,
      "reset": _vm.reset
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Create Newspaper",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-row', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Brand"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Brand",
      "open-direction": "bottom",
      "required": "required",
      "options": _vm.brandSearchOptions,
      "searchable": true,
      "loading": _vm.isBrandSearch,
      "close-on-select": true,
      "multiple": false,
      "allow-empty": false
    },
    on: {
      "search-change": _vm.brandFind,
      "input": _vm.brandSelected
    },
    model: {
      value: _vm.form.brand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "brand", $$v);
      },
      expression: "form.brand"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Display Name (Item’s Title)",
      "label-for": "display"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Display Name",
      "disabled": ""
    },
    model: {
      value: _vm.form.display_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "display_name", $$v);
      },
      expression: "form.display_name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6 button-date-custom"
    }
  }, [_c('DatetimeCustom', {
    attrs: {
      "label": 'Release Date (GMT ' + _vm.timezoneString() + ')',
      "maxDate": true
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6 button-date-custom"
    }
  }, [_c('DatetimeCustom', {
    attrs: {
      "label": 'Release Schedule (GMT ' + _vm.timezoneString() + ')',
      "maxDate": true
    },
    model: {
      value: _vm.form.release_schedule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_schedule", $$v);
      },
      expression: "form.release_schedule"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Save ")]), _c('b-button', {
    attrs: {
      "type": "reset",
      "variant": "outline-primary"
    }
  }, [_vm._v("Reset Form")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }